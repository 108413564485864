@import url("https://fonts.googleapis.com/css?family=Roboto+Serif");

body {
    background-color: #f8f8f8;
}
/* 
@media all and (max-width: 600px) {
    .navbar-nav {
        margin: 0;
    }
} */

.navbar li {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.navbar {
    background-color: #aac8a7;
    font-weight: 500;
}

.nav-link {
    color: #f2f2f2 !important;
}

.logout-text {
    text-decoration: underline;
}

.main-search {
    /* background-color: #f0eeed; */
    background-color: white;
}
.add-plants-btn {
    /* background-color: #99a98f; */
    background-color: #a9907e;
    color: white;
}

.search-btn {
    /* background-color: #99a98f; */
    background-color: #db6b97;
    color: white;
}

.image-card {
    width: 15rem;
}

.card-img-top {
    max-width: 100%;
}

.plant-profile-btn {
    background-color: #db6b97;
    color: white;
    width: 60%;
}

.plant-thumbnail {
    border-radius: 50%;
    width: 13rem;
    height: 13rem;
}

.login-header {
    color: #db6b97;
    font-family: "Roboto Serif", serif;
    font-weight: 600;
}

.google-btn {
    width: 100%;
    background-color: white;
}

.img-container {
    position: relative;
    /* border-width: 1rem; */
    /* border-style: solid; */
}
.img-container img {
    display: block;
}

.plus-sign {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    background-color: white;
    border-width: 0.2rem;
    border-style: solid;
    border-color: white;
    top: 90%;
}

.new-plant-text {
    /* font-family: "Roboto Serif", serif; */
    font-style: italic;
    /* color: blue; */
}

html {
    font-size: large;
}

/* pagination colors */
.page-item.active .page-link {
    color: #fff !important;
    background: #aac8a7 !important;
    border: solid 1px #e9ecef;
}
.page-item .page-link {
    color: #aac8a7 !important;
    background: white !important;
    border: solid 1px #e9ecef;
}
.page-item.disabled .page-link {
    color: #ced4da !important;
    background: white !important;
    border: solid 1px #e9ecef;
}
